<template>
  <v-container>
    <v-card class="content-card-padding">
      <content-template
        :items="itemsArray"
        :default-array="defaultContent"
        :custom-array="customContent"
        :enabled-languages="company.enabledLanguages"
        :show-default-custom-options="showDefaultCustomOptions"
        :show-subject="true"
        :show-send-preview="true"
        :editor-variable-slot="editorVariables"
        @on-save-content="saveContent"
        @on-send-preview-email="sendPreviewEmail"
        @on-update-content="updateCustomContent"
        @on-update-contents="updateCustomContents"
      />
    </v-card>
  </v-container>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import { ContentTemplate, ContentService } from '@kickbox/common-admin';
import { USERNAME, COMPANY_NAME, USER_UNIT } from '@kickbox/common-util/constants/editor-variables';
import emailCoachingService from '@/services/emailCoachingService';

export default {
  components: {
    ContentTemplate
  },
  data() {
    return {
      itemsArray: [],
      showDefaultCustomOptions: true,
      editorVariables: [USERNAME, COMPANY_NAME, USER_UNIT]
    };
  },
  computed: {
    ...mapGetters([
      'defaultContent',
      'customContent',
      'company',
    ]),
    contentKeys() {
      return this.itemsArray.map((content) => content.key);
    }
  },
  async created() {
    this.itemsArray = await emailCoachingService.getEmailCoachingItems();
    ContentService.getDefaultContent(this.contentKeys)
      .then((content) => {
        const updatedContent = content.map((contentItem) => ({ ...contentItem, isSaved: true }));
        this.$store.commit('setDefaultContent', updatedContent);
      });
    ContentService.getCustomContent(this.contentKeys, this.company.parseObject)
      .then((content) => {
        const updatedContent = content.map((contentItem) => ({ ...contentItem, isSaved: true }));
        this.$store.commit('setCustomContent', updatedContent);
      });
  },
  methods: {
    ...mapMutations([
      'addCustomContent',
      'deleteCustomContent',
      'updateCustomContent',
      'updateCustomContents',
    ]),
    saveContent(contentToUpdate, contentArray) {
      contentToUpdate.forEach(async (content) => {
        const result = await ContentService.manageContent(content, contentArray);
        this.updateCustomContent(result.content);
      });
      return this.$store.dispatch('showSnackBar', { text: 'The content has been updated.' });
    },
    sendPreviewEmail(emailProperties) {
      return ContentService.sendContentPreviewEmail(emailProperties)
        .then(() => this.$store.dispatch('showSnackBar', { text: 'The email was sent.' }));
    }
  }
};
</script>
